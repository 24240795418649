<template>
  <div class="header">
      <div class="system-logo">
          <div class="logo-view">
              <img :src="area_logo_image" alt="logo" />
              <span>{{name}}</span>
          </div>
          <div class="v-line"></div>
          <div>
              <img src="@/assets/images/header/sys-logo.png" alt="安优智控平台">
          </div>
      </div>
      <div class="weather-view">
         <div class="weather-user">
             <div class="weather">
                <div class="date">{{date}}</div>
                <div class="time">{{time}}</div>
                <!-- <span>图片</span> -->
                <!-- <span>{{weather.status}}</span> -->
                <!-- <span>{{weather.temperature}}</span> -->
            </div>
            <div class="user">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        <img src="@/assets/images/header/user.png">
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="name">{{area_user_real_name}}</el-dropdown-item>
                        <el-dropdown-item command="logout">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
         </div>
      </div>
      <div class="nav-menus-view">
          <NavMenu />
      </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import NavMenu from '@/components/NavMenu.vue'

export default {
    name: 'Header',
    components: { NavMenu },
    data(){
        return {
            // weather: {
            date: '',
            time: '',
            timer: null
            // }
        }
    },
    computed: {
        ...mapState({
            name: (store)=>{ return store.areaUserInfo.area_info ? store.areaUserInfo.area_info.area_name : '' } , 
            area_logo_image: (store)=>{ return store.areaUserInfo.area_info ? store.areaUserInfo.area_info.area_logo_image : '' } , 
            // areaUserInfo: (store)=>{ return store.areaUserInfo ? store.areaUserInfo : '' } , 
            area_user_real_name: (store)=>{ return store.areaUserInfo ? store.areaUserInfo.area_user_real_name : '' } , 
        })
    },
    mounted(){
        this.getDate();
    },
    beforeDestroy(){
        clearTimeout(this.timer)
    },
    methods:{
        getDate(){
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            let second = date.getSeconds();

            month = ('0' + month ).slice(-2)
            day = ('0' + day ).slice(-2)

            hour = ('0' + hour ).slice(-2)
            minute = ('0' + minute ).slice(-2)
            second = ('0' + second ).slice(-2)

            this.date = `${year}/${month}/${day}`;
            this.time = `${hour}:${minute}:${second}`;
            this.timer = setTimeout(()=>{
                this.getDate();
            }, 1000)

        },
        handleCommand(type){
            if(type === 'logout'){
                this.logout();
            }
        },
        async logout(){
            await this.$Fx('/Api_Area/users/users/logout');
            //不论 退出接口是否成功都应该 页面跳转
            sessionStorage.removeItem('area_user_info');
            this.$router.push('/login');
        }
      
    }
}
</script>

<style lang="less">
.header{
    width: 100%;
    height: 70px;
    // border: 1px solid #aaa;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../assets/images/header/header-bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    pointer-events: all;
    .system-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-view{
            display: flex;
            justify-items: center;
            align-items: center; 
            font-size: 22px;
            font-family: "Alibaba-PuHuiTi-Bold";
            font-weight: bold;
            color: #FFFFFF;
            img{
                max-width: 60px;
                max-height: 40px;
            }
            span{
                margin-left: 10px;
            }
        }
    }
    .v-line{
        width: 1px;
        height: 24px;
        background: #2C76D5;
        margin: 0 10px;
    }
    .weather-view{
        position: absolute;
        right: 20px;
        top: 0;
        height: 100%;
        width: 4rem;
        min-width: 300px;
        display: flex;
        justify-content: flex-end;
        // align-items: center;
        padding-top: 15px;
        font-size: 14px;
        color: #fff;
        .weather-user{
            display: flex;
            height: 30px;
            .user{
                cursor: pointer;
                &:hover{
                    transform: scale(1.2);
                }
            }
        }
        .weather{
            margin-right: 8px;
            display: flex;
            .date{
                width: 80px;
            }
            .time{
                width: 80px;
            }
        }
    }

    .nav-menus-view{
        position: absolute;
        left: 20px;
        bottom: -60px;
        width: 300px;
        height: 40px;
    }
}
</style>