<template>
  <div class="smart-control">
    <Header />
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {
    Header
  }
}
</script>

<style lang="less">
.smart-control {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .container {
    flex: 1 1 auto;
  }

}
</style>
