<template>
  <div class="map-view">
    <div id="container"></div>
  </div>
</template>

<script>

import close from '@/assets/images/map/close.png';
import EventBus from '@/commons/event-bus.js'
import { ICON_SET } from '@/assets/images/map/map-icons';

export default {
  data(){
    return {
      mapView: null,   //地图实例
      markerList: [],  //marker的数据集合
      allIconImg: {}, //所有的地图图标集合
      activeMarker: null, //当前选中的mark
    }
  },
  mounted() {
    EventBus.$on("changeMarkers", (data)=>{
      this.markerList = data || [];
      //清除所有的marker
      this.mapView.clearOverlays();
      this.addMask();
      //重新绘制marker
      this.renderMarkers();
    });

    EventBus.$on("moveCenter", (center)=>{
      this.moveCenter(center);
    });

    EventBus.$on("moveCenterByName", (name)=>{
      this.moveCenterByName(name);
    });

    this.initMap();
  },
  methods:{
    initMap(){
      const mapView = new BMap.Map("container",{mapType: BMAP_SATELLITE_MAP, minZoom: 5 });
      //设置地图默认中心点
      mapView.centerAndZoom(new BMap.Point(108.95333467976067, 34.26529582460059), 15); //西安钟楼位置坐标
      mapView.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      mapView.addControl(new BMap.ScaleControl());// 添加左下角比例尺控件
      mapView.addControl(new BMap.NavigationControl({anchor: BMAP_ANCHOR_BOTTOM_RIGHT}));  // 右下角放大缩小控件
      // 地图切换
      const mapType = new BMap.MapTypeControl({type: BMAP_MAPTYPE_CONTROL_HORIZONTAL, mapTypes: [BMAP_NORMAL_MAP, BMAP_SATELLITE_MAP, BMAP_HYBRID_MAP]});
      mapView.addControl(mapType);
      this.mapView = mapView;

      this.setIcons();
    },

    moveCenter({long, lat}){
      this.mapView.panTo(new BMap.Point(long, lat));
    },

    moveCenterByName(name){
      if(!name) { return; };
      const {long, lat} = this.markerList.find(item=> item.enterprise_name === name);
      this.moveCenter({long, lat});
    },

    addMask(){
     	var polygon = new BMap.Polygon([
        new BMap.Point(-180, 90),
        new BMap.Point(180, 90),
        new BMap.Point(180, -90),
        new BMap.Point(-180, -90),
        new BMap.Point(-180, 90)
      ], { fillColor:"#000", fillOpacity: 0.3 });

      this.mapView.addOverlay(polygon);
    },

    setIcons(){
      this.allIconImg = {};
      Object.keys(ICON_SET).forEach((key)=>{
          const { img, w, h } = ICON_SET[key];
          this.allIconImg[key] = new BMap.Icon(img, new BMap.Size(w, h));
      })
    },

    renderMarkers(){

        this.markerList.forEach((item,i)=>{
          const {
            long, lat, level_name, type
          } = item;

          const point = new BMap.Point(long, lat);

          //创建marker对象
          let marker = new BMap.Marker(point, {icon: this.allIconImg[level_name]});
          //将marker对象保存起来
          this.markerList[i].marker = marker;

          let infoBox = this.renderInfoBox(item);

          infoBox.addEventListener("close", ()=> {
              this.markerList.forEach(mk=>{
                  mk.marker.setIcon(this.allIconImg[mk.level_name]);
                  mk.marker.setOffset(new BMap.Size(0, 0));
              });
              this.activeMarker = null;
          });

          //添加marker点击事件
          marker.addEventListener("click", ()=>{
              // infoBox.close();
              // 清除所有点的状态
              this.markerList.forEach(mk=>{
                  mk.marker.setIcon(this.allIconImg[mk.level_name]);
              });
               marker.setOffset(new BMap.Size(0, 0));

              //如果当前点不是activeMarker，设置active状态
              if(this.activeMarker !== marker ){
                  this.activeMarker = marker;
                  marker.setIcon( this.allIconImg[`${level_name}_active`]);
                  if(type === '隐患'){
                    marker.setOffset(new BMap.Size(0, 4));
                  }
                  if(type === '区域信息'){
                    marker.setOffset(new BMap.Size(0, 5));
                  }
                  infoBox.open(marker);
              }else{
                  this.activeMarker = null;
                  infoBox.close();
              }
          })

          //将marker添加到地图上
          this.mapView.addOverlay(marker);

          if(type == '区域信息'){
              this.renderAreaLabel(item);
          }
          if(type == '隐患'){
              this.renderDangerLabel(item);
          }
        })
    },

    renderDangerLabel(item){
        const { long, lat, info: { total} } = item;
        const point = new BMap.Point(long, lat);
        const label_name = new BMap.Label( total, { offset: new BMap.Size(15, -50), position: point });
        const label_title = new BMap.Label('隐患数量', { offset: new BMap.Size(15, -25), position: point });

        label_name.setStyle({
            fontSize: '18px',
            fontFamily: 'AliHYAiHei',
            fontWeight: 400,
            color: '#FFFFFF'
        });

        label_title.setStyle({
          fontSize: '14px',
          color: '#FFFFFF',
          opacity: 0.8
        });

        this.mapView.addOverlay(label_name);
        this.mapView.addOverlay(label_title);
    },
    renderAreaLabel(item){
        const { long, lat, enterprise_name } = item;
        const point = new BMap.Point(long, lat);
        const label_name = new BMap.Label( enterprise_name, { offset: new BMap.Size(15, -50), position: point });
        const label_title = new BMap.Label('', { offset: new BMap.Size(15, -25), position: point });

        label_name.setStyle({
            fontSize: '18px',
            fontFamily: 'AliHYAiHei',
            fontWeight: 400,
            color: '#FFFFFF'
        });

        label_title.setStyle({
          fontSize: '14px',
          color: '#FFFFFF',
          opacity: 0.8
        });

        this.mapView.addOverlay(label_name);
        this.mapView.addOverlay(label_title);
    },

    renderInfoBox(data){
      if(data.type === '风险'){
        return this.riskInfoBox(data);
      }
      if(data.type === '隐患'){
        return this.dangerInfoBox(data);
      }
      if(data.type === '区域信息'){
        return this.areInfoBox(data);
      }
    },

    //风险弹框
    riskInfoBox(data){

      const {
        enterprise_name, level_name, level_id, level_color,
        info:{ major,more,common,low }
      }= data;
      const dom = `
        <div class="container">
          <div class="info-name">${enterprise_name}</div>
          <div class="level-info">
            <div class="level" style="color: ${level_color}">${level_name}</div>
            <div class="text">企业风险等级</div>
          </div>
          <div class="info-box">
            <div class="item">
                <div class="val">${ level_id != 0 ? major : '--' }</div>
                <div class="text">重大风险</div>
            </div>
            <div class="item">
                <div class="val">${ level_id != 0 ? more : '--' }</div>
                <div class="text">较大风险</div>
            </div>
            <div class="item">
                <div class="val">${ level_id != 0 ? common : '--' }</div>
                <div class="text">一般风险</div>
            </div>
            <div class="item">
                <div class="val">${ level_id != 0 ? low : '--' }</div>
                <div class="text">低风险</div>
            </div>
          </div>
        </div>
      `;

      return new BMapLib.InfoBox(this.mapView, dom, {
          boxClass: 'info-box-view risk',
          enableAutoPan: true,
          closeIconUrl: close,
          closeIconMargin: "-10px 10px 0 0",
          offset: new BMap.Size(0, 30),
          align: INFOBOX_AT_BOTTOM
      });
    },
    //隐患弹框
    dangerInfoBox(data){

      const {
        enterprise_name,
        info:{ total, doing, again, overdue, level_list }
      }= data;
      const dom = `
        <div class="container">
          <div class="info-name">${enterprise_name}</div>
          <div class="info">
            <div class="title">企业隐患等级</div>
             <div class="level-box">
              <div class="item">
                  <div class="val">${ level_list[0].total }</div>
                  <div class="text">${level_list[0].hidden_danger_level_name}</div>
              </div>
               <div class="item">
                  <div class="val">${ level_list[1].total }</div>
                  <div class="text">${level_list[1].hidden_danger_level_name}</div>
              </div>
          </div>
          <div class="info">
            <div class="title">企业隐患状态</div>
             <div class="info-box">
              <div class="item">
                  <div class="val">${ doing }</div>
                  <div class="text">整改中</div>
              </div>
               <div class="item">
                  <div class="val">${ again }</div>
                  <div class="text">再整改</div>
              </div>
               <div class="item">
                  <div class="val">${ overdue }</div>
                  <div class="text">逾期未整改</div>
              </div>
          </div>
          </div>
        </div>
      `;

      return new BMapLib.InfoBox(this.mapView, dom, {
          boxClass: 'info-box-view danger',
          enableAutoPan: true,
          closeIconUrl: close,
          closeIconMargin: "-10px 10px 0 0",
          offset: new BMap.Size(0, 50),
          align: INFOBOX_AT_BOTTOM
      });
    },

    //区域信息弹框
    areInfoBox(data){
      const {
        enterprise_name,
        info:{ person, phone, address, class_info, three, hazard, level }
      }= data;
      const dom = `
        <div class="container">
          <div class="info-name">${enterprise_name}</div>
          <div class="info-ver" style="margin: 5px 0 0 0">
              <div class="item" style="width: 50%">
                  <div class="text">企业法人</div>
                  <div class="text-val">${ person }</div>
              </div>
              <div class="item" style="width: 50%">
                  <div class="text">联系方式</div>
                  <div class="text-val">${ phone }</div>
              </div>
          </div>
          <div class="info-ver" style="margin: 5px 0">
              <div class="item">
                  <div class="text">企业地址</div>
                  <div class="text-val">${ address }</div>
              </div>
          </div>
          <div class="info-ver">
              <div class="item">
                  <div class="text">企业类型</div>
                  <div class="text-val">${ class_info }</div>
              </div>
          </div>
          <div class="info-ver" style="margin: 5px 0">
               <div class="item" style="width: 50%">
                  <div class="text">"三同时" 完成情况</div>
                  <div class="text-val">${ three }</div>
              </div>
              <div class="item" style="width: 50%">
                  <div class="text">重大危险源</div>
                  <div class="text-val">${ hazard }</div>
              </div>
          </div>
          <div class="info-ver">
               <div class="item">
                  <div class="text">企业风险等级</div>
                  <div class="text-val">${ level }</div>
              </div>
          </div>
        </div>
      `;

      return new BMapLib.InfoBox(this.mapView, dom, {
          boxClass: 'info-box-view danger',
          enableAutoPan: true,
          closeIconUrl: close,
          closeIconMargin: "-10px 10px 0 0",
          offset: new BMap.Size(0, 50),
          align: INFOBOX_AT_BOTTOM
      });
    }

  }
}
</script>

<style lang="less">
.map-view {
 width: 100%;
 height: 100%;
 #container{
   width: 100%;
   height: 100%;
 }

 .BMap_noprint.anchorTR{
   inset: 80px 10px auto auto !important;
 }

 .info-box-view{
     //height: 180px;
    border: 1px solid #aaa ;
    background: linear-gradient(0deg, rgba(91, 111, 138, 0.4) , rgba(48, 100, 173, 0.9));
    color:#fff;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #3F89EE;
    &.risk{
      width: 200px;
    }
    &.danger{
      width: 300px;
      .level-box{
        margin: 10px 0;
        .item{
          width: 40% !important;
        }
      }
      .info-box{
        .item{
          width: 30% !important;
        }
      }
    }
    .container{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        .title{
          margin: 10px 0 0 0;
        }
        .info-name{
          font-family: 'AliHYAiHei';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 17px;
        }
        .text{
          font-family: 'Alibaba-PuHuiTi-Regular';
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
        }
        .title{
            font-size: 16px;
            font-family: 'Alibaba-PuHuiTi-Regular';
            font-weight: 400;
            color: #FFFFFF;
        }
        .val{
            font-size: 20px;
            font-family: 'Alibaba-PuHuiTi-Bold';
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
        }
        .text-val{
            font-family: 'Alibaba-PuHuiTi-Bold';
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
        }
        .info-ver{
          display: flex;
          justify-content: space-between;
        }
        .level-info{
          margin: 10px 0;
          .level{
            font-family: 'Alibaba-PuHuiTi-Regular';
            font-weight: bold;
            // background: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
        .info-box, .level-box{
          display: flex;
          flex-wrap: wrap;
          .item{
            width: 50%;
            height: 50px;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
          }
        }
    }
}

.BMapLabel{
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    color: #fff !important;
}
}

</style>
