<template>
  <BorderBox :styles="{border: '1px solid rgba(76, 156, 255, 0.7)'}">
    <div class="nav-menus">
        <div v-for="item in menus" :key="item.name" class="item">
            <router-link :to="item.path" exact>{{ item.name }}</router-link>
        </div>
    </div>
  </BorderBox>
</template>

<script>
import BorderBox from '@/components/BorderBox.vue';

export default {
    name: 'NavMenu',
    components: { BorderBox },
    data(){
        return {
            menus: [
                {name: '风险', path: '/dashboard/risk'},
                {name: '隐患', path: '/dashboard/hidden-danger'},
                {name: '区域信息', path: '/dashboard/are-information'},
                {name: '法律法规', path: '/dashboard/laws-regulations'},
            ]
        }
    }
}
</script>

<style lang="less">
.nav-menus{
    height: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    background: rgba(4, 17, 34, 0);
    box-shadow: 0px 0px 20px rgba(76, 156, 255, 1) inset;
    pointer-events: all;
    border-radius: 10px;
    font-family: "Alibaba-PuHuiTi-Medium";
    .item{
        background: linear-gradient(0deg, #fff 0%, #fff 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        .router-link-active{
            background: linear-gradient(0deg, #308BFE 0%, #7DEBFF 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            position: relative;
            &::after{
                content: '';
                width: 30px;
                height: 5px;
                // background: red;
                position: absolute;
                border-radius: 50%;
                background-image: radial-gradient(rgba(146, 192, 247, 0.7) 20%, rgba(113, 36, 36, 0.2) 90%);
                left: 50%;
                bottom: -4px;
                margin-left: -15px;
            }
        }
    }
}
</style>