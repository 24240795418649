import png_risk0 from './未评估综合风险等级.png';
import png_risk0_active from './未评估综合风险等级_active.png';
import png_risk1 from './重大风险.png';
import png_risk1_active from './重大风险_active.png';
import png_risk2 from './较大风险.png';
import png_risk2_active from './较大风险_active.png';
import png_risk3 from './一般风险.png';
import png_risk3_active from './一般风险_active.png';
import png_risk4 from './低风险.png';
import png_risk4_active from './低风险_active.png';

import png_danger0 from './无隐患.png';
import png_danger0_active from './无隐患_active.png';
import png_danger1 from './重大隐患.png';
import png_danger1_active from './重大隐患_active.png';
import png_danger2 from './一般隐患.png';
import png_danger2_active from './一般隐患_active.png';

import png_info1 from './区域信息.png';
import png_info1_active from './区域信息_active.png';

export const ICON_SET = {
    "未评估综合风险等级": {img: png_risk0, w: 44, h: 44},
    "未评估综合风险等级_active":  {img: png_risk0_active, w: 66, h: 66},
    "重大风险": {img: png_risk1, w: 44, h: 44},
    "重大风险_active":  {img: png_risk1_active, w: 66, h: 66},
    "较大风险": {img: png_risk2, w: 44, h: 44},
    "较大风险_active":  {img: png_risk2_active, w: 66, h: 66},
    "一般风险": {img: png_risk3, w: 44, h: 44},
    "一般风险_active":  {img: png_risk3_active, w: 66, h: 66},
    "低风险": {img: png_risk4, w: 44, h: 44},
    "低风险_active":  {img: png_risk4_active, w: 66, h: 66},
    "无隐患": {img: png_danger0, w: 44, h: 96},
    "无隐患_active": {img: png_danger0_active, w: 66, h: 107},
    "重大隐患": {img: png_danger1, w: 44, h: 98},
    "重大隐患_active": {img: png_danger1_active, w: 66, h: 108},
    "一般隐患": {img: png_danger2, w: 44, h: 98},
    "一般隐患_active": {img: png_danger2_active, w: 66, h: 108},
    "区域信息": {img: png_info1, w: 44, h: 96},
    "区域信息_active": {img: png_info1_active, w: 66, h: 108},
  };
  


