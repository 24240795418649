<template>
  <div class="dashboard">
    <div class="map-view-container">
      <MapView />
    </div>
    <div class="mask-view"></div>
    <div class="smart-control-container">
      <SmartControl />
    </div>
  </div>
</template>

<script>
import SmartControl from './smart-control/Index.vue'
import MapView from './map-view/MapView.vue'

export default {
  name: 'Dashboard',
  components: {
    SmartControl,
    MapView
  }
}
</script>



<style lang="less">
.dashboard {
  width: 100%;
  height: 100%;
  position: relative;
  .smart-control-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    pointer-events: none;
  }
  .map-view-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .mask-view{
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-image: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 35%, #0f2038 75%);
      background-position: 14px -14px;
      z-index: 1;
      z-index: 11;
  }
}

</style>
